import React,{ Suspense, Component } from 'react';
const Main = React.lazy(() => import('./Main'));



class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //    logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      let message = this.state.error || 'no error message returned'
      if(message.includes('chunk')){
        message = 'The App encountered a network timeout while installing a new version.<br/> Please ensure you have good network connectivity and try again.<br/>Technical error returned:<br/>'+message 
      }    
      return (
        <div>
          <h2>Application error</h2>
          <p>{this.state.error}</p>
          <p><button onClick={ ()=>{window.location.reload()} }>Try Reload</button></p>
        </div>
      )
    }

    return this.props.children; 
  }
}

const LoadingMessage = ({message}) => (
  <div style={{position:'fixed', width:'100%', maxWidth:1280, height:'100%', display:'flex', alignItems:'center',justifyContent:'center' }}>
    <div>
      {message || 'Loading App Please Wait...'}
    </div>
  </div>
)


// We split out the App to a separate Chunk so we can ensure that we load the initial page and inform users if the App is loading
export default () => {
  return (
    <ErrorBoundary>
        <Suspense fallback={LoadingMessage} >
          <Main/>
        </Suspense>
    </ErrorBoundary>
  );
}