import './polyfills.js';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import registerServiceWorker from './registerServiceWorker';
// import 'material-design-icons/iconfont/material-icons.css'
// import 'typeface-roboto/index.css'
document.getElementById('root').innerHTML='<div style="position: fixed; width:100%; max-width:1280px; height:100%; display: flex; align-items: center; justify-content: center;"><div>Loading App...</div></div>'

ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

// Learn more about service workers: http://bit.ly/CRA-PWA



// Reload page if service worker controller is changed. We ensure no infinite loop by setting the refreshing
let refreshing;
// The event listener that is fired when the service worker updates
// Here we reload the page
if('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    localStorage.setItem('sw-state', 'READY');
    console.log('automatic reload of page after shifting to new version of App. This is necessary to avoid it being stuck on Apple if added to home screen')
    refreshing = true;
    });
}

serviceWorker.register({
    onUpdate: (registration) => {
        localStorage.setItem('sw-state', 'NEW');
        console.log('New App Version Availalble')
        console.log(registration);

        if(registration){
            if(registration.waiting){
                window.alert('New Version of the App . We will try and refresh App for you')
//                navigator.serviceWorker.controller.postMessage("skipWaiting");
            }
        }
    },
    onSuccess: () => {
        localStorage.setItem('sw-state', 'READY');
        console.log('SERVICE WORKER: App downloaded and ready for use offline')
    }
});
